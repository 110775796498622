.cui-app-main.mat-typography {
  ocf-project-list-table,
  ocf-service-list-table,
  ocf-nav-upgrade-simulate-tasks,
  ocf-service-types-table,
  ocf-nav-upgrade-action-log,
  ocf-nav-add-ons-table,
  ocf-nav-upgrade-simulate-excl-tasks,
  nav-upgrade-reports-modal,
  ocf-ax-add-on-table {
    .mat-mdc-icon-button.mat-mdc-button-base {
      display: inline-block;
    }
  }
  .mat-mdc-icon-button.mat-mdc-button-disabled .mat-mdc-button-persistent-ripple::before {
    background-color: transparent;
  }

  .mat-mdc-icon-button:active > .mat-mdc-button-persistent-ripple::before {
    opacity: 0;
  }
}
