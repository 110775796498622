@use 'cui-components/styles/styles.scss';
@use 'cui-components/styles/configs/layout.scss' as layout;
@use './app/news-articles/export.scss' as export;
@use './styles/projects.styles.scss';

@media (max-width: 600px) {
  html {
    font-size: 13px; /* Smaller base font size for mobile */
  }
}

.cui-app-main.mat-typography {
  cui-app-container {
    background-color: var(--cui-color-page-bg);
  }

  .mat-horizontal-content-container {
    padding: 0;
  }

  .mat-mdc-raised-button.mat-accent {
    --mdc-protected-button-label-text-color: var(--color-white);
  }

  mat-dialog-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: var(--cui-small-space);
    width: 100%;
  }

  .promoted-container .carousel-view cui-icon-btn .mat-mdc-icon-button.mat-mdc-button-base {
    --mdc-icon-button-state-layer-size: 3rem;
  }

  ocf-ticket-create-dialog {
    cui-rich-editor {
      mat-form-field {
        padding: var(--cui-small-space) 0;
      }
    }
  }

  ocf-ticket-attachments,
  ocf-ticket-create-dialog {
    cui-rich-editor {
      .jodit-progress-bar div {
        display: none;
      }
    }
  }
}
//** Need to be removed after Projects modal removal

.text-center {
  text-align: center !important;
}

mat-card-title {
  line-height: 30px;
}

.pad- {
  &top- {
    &0 {
      padding-top: 0;
    }

    &1 {
      padding-top: 8px;
    }

    &2 {
      padding-top: 16px;
    }
  }

  &bottom- {
    &0 {
      padding-bottom: 0;
    }

    &1 {
      padding-bottom: 8px;
    }

    &2 {
      padding-bottom: 16px;
    }
  }

  &left- {
    &0 {
      padding-left: 0;
    }

    &1 {
      padding-left: 8px;
    }

    &2 {
      padding-left: 16px;
    }
  }
}

.margin- {
  &top- {
    &0 {
      margin-top: 0 !important;
    }

    &1 {
      margin-top: 8px !important;
    }

    &2 {
      margin-top: 16px !important;
    }
  }

  &bottom- {
    &0 {
      margin-bottom: 0 !important;
    }

    &1 {
      margin-bottom: 8px !important;
    }

    &2 {
      margin-bottom: 16px !important;
    }
  }
}

.mat-mdc-row {
  height: var(--mat-table-row-item-container-height, 2rem);
  color: var(--mat-table-row-item-label-text-color, rgba(0, 0, 0, 0.87));
}

cui-checkbox {
  mat-checkbox {
    div.mdc-form-field {
      align-items: flex-start;

      label.mdc-label {
        align-self: center;
      }
    }
  }
}

mat-hint {
  color: var(--color-gray-medium);
}

.empty-table-message {
  padding-top: 3em;
  padding-bottom: 3em;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}

.overflow-container {
  overflow-x: auto;
  max-width: 100%;
  overflow-y: hidden;
}

.width-100 {
  width: 100%;
}

.w- {
  &100 {
    width: 100%;
  }

  &90 {
    width: 90%;
  }

  &80 {
    width: 80%;
  }

  &70 {
    width: 70%;
  }

  &60 {
    width: 60%;
  }

  &50 {
    width: 50%;
  }

  &40 {
    width: 40%;
  }

  &30 {
    width: 30%;
  }

  &20 {
    width: 20%;
  }

  &10 {
    width: 10%;
  }
}

.height-100 {
  height: 100%;
}

.disabled {
  color: rgba(0, 0, 0, 0.38);

  label {
    color: rgba(0, 0, 0, 0.6);
  }
}

.info-text {
  font-size: 75%;
  // color: var(--cui-shadow);
}

.custom-modal-container .mat-dialog-container {
  padding: 0;
}

// Style rules for Toggle
.mat-button-toggle-checked {
  background-color: var(--cui-color-cta);
  span.mat-button-toggle-label-content {
    color: var(--cui-color-card-bg);
  }
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px var(--cui-color-cta);
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px var(--cui-color-cta);
}

.mat-button-toggle-appearance-standard:not(.mat-button-toggle-checked) {
  color: var(--cui-color-cta);
  background: var(--cui-color-card-bg);
}

.mat-slide-toggle-bar:hover .mat-slide-toggle-persistent-ripple {
  opacity: 0 !important;
}

// Cui-Card style
div.body {
  div.body-content {
    overflow: auto;
  }
}

.cdk-dialog-container:focus-visible {
  outline: none;
}

.no-overflow-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.pulse {
  animation: pulse 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  animation-delay: 0.5s;
}

@keyframes pulse {
  0% {
    opacity: 0.9;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.9;
  }
}

:root {
  --cui-color-highlight: #e1e1e1;

  --promoted-article-height: 25rem;
  //promoted article min width is defined in promoted-news-article.component.ts since it's calculated on resize, not used in style files
  --article-list-item-height: 31.25rem;
  --article-list-item-min-width: 21.875rem;

  --tag-background: #e0e0e0;
  --tag-background-hover: #d0d0d0;
  --tag-background-active: #c0c0c0;
  --tag-background-enabled: #86c5ff;
  --tag-background-enabled-hover: #5db0ff;
  --tag-background-enabled-active: #44a4ff;
  --border: #d0d0d0;
  --box-shadow: #e0e0e0;
  --filter-container-height: 300px;
  --filter-search-bar-height: 35px;

  --promoted-article-user-like: #44a4ff;
  --x-small-space: 5px;
  --x-small-font: 13px;

  // Mat table
  --mat-table-row-item-container-height: 2rem;
}

@media screen and (max-width: (export.$width-sm - 1px)) {
  :root {
    --filter-container-height: 400px;
  }
}
